// pages/404.tsx

import React from "react";
import { useNavigate } from "react-router-dom";

const PageNotFound: React.FC = () => {
  const router = useNavigate();

  return (
    <div className="flex items-center justify-center h-screen bg-gray-100">
      <div className="text-center">
        {/* <Image
          src="/404-image.png"
          alt="Page Not Found"
          width={300}
          height={300}
          className="mx-auto"
        /> */}
        <h1 className="text-2xl font-bold text-gray-900 mt-5">
          Uh-oh! We can't find that page.
        </h1>
        <p className="text-gray-700">
          The page you're looking for might have been removed or is temporarily
          unavailable.
        </p>
        <button
          onClick={() => router("/")}
          className="mt-6 px-5 py-2 bg-green-500 text-white rounded-lg shadow"
        >
          Go Home
        </button>
      </div>
    </div>
  );
};

export default PageNotFound;
