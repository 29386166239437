import "./App.css";
import { RouterProvider } from "react-router-dom";

import { mainWebsiteRoutes } from "./routes/main.routes";
import { getSubDomainName } from "./lib/utils";
import { subDomainRoutes } from "./routes/app.routes";

function App() {
  const isSubDomain = getSubDomainName();

  const routes = isSubDomain ? subDomainRoutes : mainWebsiteRoutes;

  return <RouterProvider router={routes} />;
}

export default App;
