import { Suspense, lazy } from "react";
import { ErrorBoundary } from "react-error-boundary";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Route,
} from "react-router-dom";
import { ADMIN_ROLES } from "../constants/consts";
import TermsAndConditions from "../page/TermsAndConditions";
import PrivacyPolicy from "../page/PrivacyPolicy";
const PublicActivities = lazy(() => import("../page/PublicActivities"));
const PublicIndividualActivity = lazy(
  () => import("../page/PublicIndividualActivity"),
);
const PublicBookings = lazy(() => import("../page/PublicBookings"));
const PublicIndividualBooking = lazy(
  () => import("../page/PublicIndividualBooking"),
);
const PublicSessionDetails = lazy(() => import("../page/PublicSessionDetails"));
const PublicAttendees = lazy(() => import("../page/PublicAttendees"));
const CheckoutStepForm = lazy(() => import("../components/CheckoutStepForm"));
const BookingUserAccountDetails = lazy(
  () => import("../page/BookingUserAccountDetails"),
);
const SignUp = lazy(() => import("../page/SignUp"));
const EmailVerification = lazy(() => import("../page/EmailVerification"));
const Login = lazy(() => import("../page/Login"));
const ForgotPassword = lazy(() => import("../page/ForgotPassword"));
const RequireAuth = lazy(
  () => import("../components/RequiredAuth/RequiredAuth"),
);
const RequireRole = lazy(() => import("../components/RequireRole/RequireRole"));
const AppLayout = lazy(() => import("../components/Layout/AppLayout"));
const AllBookings = lazy(() => import("../page/AllBookings"));
const IndividualBooking = lazy(() => import("../page/IndividualBooking"));
const CreateBooking = lazy(() => import("../page/CreateBooking"));
const Dashboard = lazy(() => import("../page/Dashboard"));
const Activities = lazy(() => import("../page/Activities"));
const IndividualActivity = lazy(
  () => import("../components/Activities/IndividualActivity"),
);
const CreateActivities = lazy(
  () => import("../components/Activities/CreateActivities"),
);
const AttendeeRegisterMain = lazy(
  () => import("../components/Session/AttendeeRegisterMain"),
);

const CombinedSessionRegister = lazy(
  () => import("../components/Session/CombinedSessionRegister"),
);
const IndividualSessionRegister = lazy(
  () => import("../components/Session/IndividualSessionRegister"),
);
const SessionsDetails = lazy(() => import("../page/SessionDetails"));
const CreateNewSession = lazy(
  () => import("../components/Activities/CreateNewSession"),
);
const CreatePackagedSession = lazy(
  () => import("../components/PackagedSessions/CreatePackagedSession"),
);
const Users = lazy(() => import("../page/Users"));
const IndividualUser = lazy(() => import("../page/IndividualUser"));
const StaffUser = lazy(() => import("../page/StaffUser"));
const EditUser = lazy(() => import("../components/Users/EditUser"));
const Attendees = lazy(() => import("../page/Attendees"));
const IndividualAttendee = lazy(() => import("../page/IndividualAttendee"));
const EditAttendee = lazy(() => import("../components/Attendees/EditAttendee"));
const OrganizationManagement = lazy(() => import("../page/Organization"));
const CreateDiscount = lazy(
  () => import("../components/Discount/CreateDiscount"),
);
const Discount = lazy(() => import("../page/Discount"));
const Unauthorized = lazy(() => import("../components/Unauthorized"));
const PageNotFound = lazy(() => import("../components/PageNotFound"));
const RequireValidOrgSubDomain = lazy(
  () =>
    import("../components/RequireValidOrgSubDomain/RequireValidOrgSubDomain"),
);
const ResetPassword = lazy(() => import("../page/ResetPassword"));
const VerifyEmail = lazy(() => import("../page/VerifyEmail"));
const ErrorFallback = lazy(() => import("../components/ErrorFallback"));
const ScreenLoader = lazy(() => import("../components/ScreenLoader"));
const PublicAppLayout = lazy(
  () => import("../components/Layout/PublicAppLayout"),
);

export const subDomainRoutes = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route element={<RequireValidOrgSubDomain />}>
        {/* Public routes */}
        <Route
          path="/"
          element={
            <ErrorBoundary FallbackComponent={ErrorFallback}>
              <Suspense fallback={<ScreenLoader />}>
                {/* <RequireValidOrgSubDomain /> */}
                <PublicAppLayout />
              </Suspense>
            </ErrorBoundary>
          }
        >
          <Route index element={<Navigate replace to="/activities" />} />
          <Route
            path="/activities/"
            element={
              <ErrorBoundary FallbackComponent={ErrorFallback}>
                <Suspense fallback={<ScreenLoader />}>
                  <PublicActivities />
                </Suspense>
              </ErrorBoundary>
            }
          />
          <Route
            path="/activity/:id"
            element={
              <ErrorBoundary FallbackComponent={ErrorFallback}>
                <Suspense fallback={<ScreenLoader />}>
                  <PublicIndividualActivity />
                </Suspense>
              </ErrorBoundary>
            }
          />
          <Route
            path="/bookings/"
            element={
              <ErrorBoundary FallbackComponent={ErrorFallback}>
                <Suspense fallback={<ScreenLoader />}>
                  <PublicBookings />
                </Suspense>
              </ErrorBoundary>
            }
          />
          <Route
            path="/bookings/:id"
            element={
              <ErrorBoundary FallbackComponent={ErrorFallback}>
                <Suspense fallback={<ScreenLoader />}>
                  <PublicIndividualBooking />
                </Suspense>
              </ErrorBoundary>
            }
          />
          <Route
            path="/session/:id"
            element={
              <ErrorBoundary FallbackComponent={ErrorFallback}>
                <Suspense fallback={<ScreenLoader />}>
                  <PublicSessionDetails />
                </Suspense>
              </ErrorBoundary>
            }
          />
          <Route
            path="/attendees"
            element={
              <ErrorBoundary FallbackComponent={ErrorFallback}>
                <Suspense fallback={<ScreenLoader />}>
                  <PublicAttendees />
                </Suspense>
              </ErrorBoundary>
            }
          />
          {/* <Route
            path="/cart"
            element={
              <ErrorBoundary FallbackComponent={ErrorFallback}>
                <Cart />
              </ErrorBoundary>
            }
          /> */}
          <Route
            path="/checkout/attendee"
            element={
              <ErrorBoundary FallbackComponent={ErrorFallback}>
                <Suspense fallback={<ScreenLoader />}>
                  <CheckoutStepForm />
                </Suspense>
              </ErrorBoundary>
            }
          />
          <Route
            path="/account"
            element={
              <ErrorBoundary FallbackComponent={ErrorFallback}>
                <Suspense fallback={<ScreenLoader />}>
                  <BookingUserAccountDetails />
                </Suspense>
              </ErrorBoundary>
            }
          />
          <Route
            path="/termsandconditions"
            element={
              <ErrorBoundary FallbackComponent={ErrorFallback}>
                <Suspense fallback={<ScreenLoader />}>
                  <TermsAndConditions />
                </Suspense>
              </ErrorBoundary>
            }
          />
          <Route
            path="/privacy"
            element={
              <ErrorBoundary FallbackComponent={ErrorFallback}>
                <Suspense fallback={<ScreenLoader />}>
                  <PrivacyPolicy />
                </Suspense>
              </ErrorBoundary>
            }
          />
        </Route>

        {/* Auth routes */}
        <Route
          path="/register"
          element={
            <ErrorBoundary FallbackComponent={ErrorFallback}>
              <Suspense fallback={<ScreenLoader />}>
                <SignUp />
              </Suspense>
            </ErrorBoundary>
          }
        />
        {/* <Route
            path="/register/organization"
            element={
              <ErrorBoundary FallbackComponent={ErrorFallback}>
                <Suspense fallback={<ScreenLoader />}>
                  <SignUpOrganization />
                </Suspense>
              </ErrorBoundary>
            }
          /> */}
        <Route
          path="/otp-verification"
          element={
            <ErrorBoundary FallbackComponent={ErrorFallback}>
              <Suspense fallback={<ScreenLoader />}>
                <EmailVerification />
              </Suspense>
            </ErrorBoundary>
          }
        />

        <Route
          path="/login"
          element={
            <ErrorBoundary FallbackComponent={ErrorFallback}>
              <Suspense fallback={<ScreenLoader />}>
                <Login />
              </Suspense>
            </ErrorBoundary>
          }
        />
        <Route
          path="/forgot-password"
          element={
            <ErrorBoundary FallbackComponent={ErrorFallback}>
              <Suspense fallback={<ScreenLoader />}>
                <ForgotPassword />
              </Suspense>
            </ErrorBoundary>
          }
        />
        <Route
          path="/reset-password"
          element={
            <ErrorBoundary FallbackComponent={ErrorFallback}>
              <Suspense fallback={<ScreenLoader />}>
                <ResetPassword />
              </Suspense>
            </ErrorBoundary>
          }
        />
        <Route
          path="/verify-email"
          element={
            <ErrorBoundary FallbackComponent={ErrorFallback}>
              <Suspense fallback={<ScreenLoader />}>
                <VerifyEmail />
              </Suspense>
            </ErrorBoundary>
          }
        />

        {/* Admin Routes  */}
        <Route
          path="/admin"
          element={
            <ErrorBoundary FallbackComponent={ErrorFallback}>
              <Suspense fallback={<ScreenLoader />}>
                <RequireAuth />
              </Suspense>
            </ErrorBoundary>
          }
        >
          <Route
            path="/admin"
            element={<RequireRole allowedRoles={ADMIN_ROLES} />}
          >
            <Route element={<AppLayout />}>
              <Route
                index
                element={<Navigate replace to="/admin/bookings" />}
              />
              <Route
                path="/admin/bookings"
                element={
                  <ErrorBoundary FallbackComponent={ErrorFallback}>
                    <Suspense fallback={<ScreenLoader />}>
                      {/* <RequireAccessRole allowedRoles={["admin"]}> */}
                      <AllBookings />
                      {/* </RequireAccessRole> */}
                    </Suspense>
                  </ErrorBoundary>
                }
              />
              <Route
                path="/admin/bookings/:id"
                element={
                  <ErrorBoundary FallbackComponent={ErrorFallback}>
                    <Suspense fallback={<ScreenLoader />}>
                      <IndividualBooking />
                    </Suspense>
                  </ErrorBoundary>
                }
              />
              <Route
                path="/admin/bookings/create"
                element={
                  <ErrorBoundary FallbackComponent={ErrorFallback}>
                    <CreateBooking />
                  </ErrorBoundary>
                }
              />
              <Route
                path="/admin/dashboard"
                element={
                  <ErrorBoundary FallbackComponent={ErrorFallback}>
                    <Suspense fallback={<ScreenLoader />}>
                      <Dashboard />
                    </Suspense>
                  </ErrorBoundary>
                }
              />
              <Route
                path="/admin/activities"
                element={
                  <ErrorBoundary FallbackComponent={ErrorFallback}>
                    <Suspense fallback={<ScreenLoader />}>
                      <Activities />{" "}
                    </Suspense>
                  </ErrorBoundary>
                }
              />
              <Route
                path="/admin/activities/:id"
                element={
                  <ErrorBoundary FallbackComponent={ErrorFallback}>
                    <Suspense fallback={<ScreenLoader />}>
                      <IndividualActivity />{" "}
                    </Suspense>
                  </ErrorBoundary>
                }
              />
              <Route
                path="/admin/activities/:id/edit"
                element={
                  <ErrorBoundary FallbackComponent={ErrorFallback}>
                    <Suspense fallback={<ScreenLoader />}>
                      <CreateActivities />{" "}
                    </Suspense>
                  </ErrorBoundary>
                }
              />
              <Route
                path="/admin/activities/create"
                element={
                  <ErrorBoundary FallbackComponent={ErrorFallback}>
                    <Suspense fallback={<ScreenLoader />}>
                      <CreateActivities />
                    </Suspense>
                  </ErrorBoundary>
                }
              />
              <Route
                path="/admin/register"
                element={
                  <ErrorBoundary FallbackComponent={ErrorFallback}>
                    <Suspense fallback={<ScreenLoader />}>
                      <AttendeeRegisterMain />
                    </Suspense>
                  </ErrorBoundary>
                }
              />
              <Route
                path="/admin/register/combined/:date"
                element={
                  <ErrorBoundary FallbackComponent={ErrorFallback}>
                    <Suspense fallback={<ScreenLoader />}>
                      <CombinedSessionRegister />
                    </Suspense>
                  </ErrorBoundary>
                }
              />
              <Route
                path="/admin/register/single/:id"
                element={
                  <ErrorBoundary FallbackComponent={ErrorFallback}>
                    <Suspense fallback={<ScreenLoader />}>
                      <IndividualSessionRegister />
                    </Suspense>
                  </ErrorBoundary>
                }
              />
              <Route
                path="/admin/session/:id"
                element={
                  <ErrorBoundary FallbackComponent={ErrorFallback}>
                    <Suspense fallback={<ScreenLoader />}>
                      <SessionsDetails />
                    </Suspense>
                  </ErrorBoundary>
                }
              />
              <Route
                path="/admin/session/create/new"
                element={
                  <ErrorBoundary FallbackComponent={ErrorFallback}>
                    <Suspense fallback={<ScreenLoader />}>
                      <CreateNewSession />
                    </Suspense>
                  </ErrorBoundary>
                }
              />
              <Route
                path="/admin/session/:id/create"
                element={
                  <ErrorBoundary FallbackComponent={ErrorFallback}>
                    <Suspense fallback={<ScreenLoader />}>
                      <CreateNewSession />
                    </Suspense>
                  </ErrorBoundary>
                }
              />
              <Route
                path="/admin/session/:id/edit"
                element={
                  <ErrorBoundary FallbackComponent={ErrorFallback}>
                    <Suspense fallback={<ScreenLoader />}>
                      <CreateNewSession />
                    </Suspense>
                  </ErrorBoundary>
                }
              />
              <Route
                path="/admin/packaged-session/create"
                element={
                  <ErrorBoundary FallbackComponent={ErrorFallback}>
                    <Suspense fallback={<ScreenLoader />}>
                      <CreatePackagedSession />
                    </Suspense>
                  </ErrorBoundary>
                }
              />
              <Route
                path="/admin/users"
                element={
                  <ErrorBoundary FallbackComponent={ErrorFallback}>
                    <Suspense fallback={<ScreenLoader />}>
                      <Users />
                    </Suspense>
                  </ErrorBoundary>
                }
              />
              <Route
                path="/admin/users/:id"
                element={
                  <ErrorBoundary FallbackComponent={ErrorFallback}>
                    <Suspense fallback={<ScreenLoader />}>
                      <IndividualUser />
                    </Suspense>
                  </ErrorBoundary>
                }
              />
              <Route
                path="/admin/users/staff/:id"
                element={
                  <ErrorBoundary FallbackComponent={ErrorFallback}>
                    <Suspense fallback={<ScreenLoader />}>
                      <StaffUser />
                    </Suspense>
                  </ErrorBoundary>
                }
              />
              <Route
                path="/admin/users/:id/edit"
                element={
                  <ErrorBoundary FallbackComponent={ErrorFallback}>
                    <Suspense fallback={<ScreenLoader />}>
                      <EditUser />{" "}
                    </Suspense>
                  </ErrorBoundary>
                }
              />
              {/* <Route path="/users/create" element={<CreateUser />} /> */}
              <Route
                path="/admin/attendees"
                element={
                  <ErrorBoundary FallbackComponent={ErrorFallback}>
                    <Suspense fallback={<ScreenLoader />}>
                      <Attendees />
                    </Suspense>
                  </ErrorBoundary>
                }
              />
              <Route
                path="/admin/attendees/:id"
                element={
                  <ErrorBoundary FallbackComponent={ErrorFallback}>
                    <Suspense fallback={<ScreenLoader />}>
                      <IndividualAttendee />
                    </Suspense>
                  </ErrorBoundary>
                }
              />
              <Route
                path="/admin/attendees/:id/edit"
                element={
                  <ErrorBoundary FallbackComponent={ErrorFallback}>
                    <Suspense fallback={<ScreenLoader />}>
                      <EditAttendee />
                    </Suspense>
                  </ErrorBoundary>
                }
              />
              <Route
                path="/admin/organization"
                element={
                  <ErrorBoundary FallbackComponent={ErrorFallback}>
                    <Suspense fallback={<ScreenLoader />}>
                      <OrganizationManagement />
                    </Suspense>
                  </ErrorBoundary>
                }
              />

              <Route path="/admin/reports" element={<div>Reports</div>} />
              <Route path="admin/discounts" element={<Discount />} />
              <Route
                path="/admin/discounts/create"
                element={<CreateDiscount />}
              />
              <Route path="/admin/passes" element={<div>Passes</div>} />
              <Route
                path="/admin/subscriptions"
                element={<div>Subscriptions</div>}
              />
              <Route path="/admin/settings" element={<div>Settings</div>} />
            </Route>
          </Route>
        </Route>
      </Route>

      <Route path="/unauthorized" element={<Unauthorized />} />
      <Route path="*" element={<PageNotFound />} />
      <Route path="/invalid-subdomain" element={<PageNotFound />} />
    </>,
  ),
);
