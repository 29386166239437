import { Suspense } from "react";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
} from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";

import ErrorFallback from "../components/ErrorFallback";
import ScreenLoader from "../components/ScreenLoader";

import PageNotFound from "../components/PageNotFound";
import SignUpOrganization from "../page/SignUpOrganization";

import MainPage from "../page/MainPage";

export const mainWebsiteRoutes = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route
        path="/"
        index
        element={
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <Suspense fallback={<ScreenLoader />}>
              <MainPage />
            </Suspense>
          </ErrorBoundary>
        }
      />

      <Route
        path="/register"
        element={
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <Suspense fallback={<ScreenLoader />}>
              <SignUpOrganization />
            </Suspense>
          </ErrorBoundary>
        }
      />
      <Route path="*" element={<PageNotFound />} />
    </>,
  ),
);
